 <template>
    <div class="landing-page">
      <b-modal id="modal-landing" centered v-model="mostrarModal">
          <!-- <div class="d-flex justify-content-center mb-10">
              <img src="@/assets/img/logo/logiga-escuela-long-logo.png" alt="logo" style="width: 150px; height: 45px;"/>
          </div>
          <div class="section__title-wrapper mb-40">
             <p><b>Reciba información directamente por correo electrónico.</b></p>
          </div>
          <div class="contact__form">
             <Form @submit="submitContactForm" :validation-schema="getValidationSchema">
                <div class="row">
                   <div class="col-md-12">
                      <div class="contact__form-input mb-25">
                         <Field name="nombres" v-slot="{ field, errors, errorMessage }">
                            <input type="text" placeholder="Nombres" v-model="landing_nombres" v-bind="field" class="form-control form-control-contact" :class="[{'is-invalid': errors.length>0}]">
                            <span class="text-danger">{{ errorMessage }}</span>
                         </Field>
                      </div>
                   </div>
                   <div class="col-md-12">
                      <div class="contact__form-input mb-25">
                         <Field name="email" v-slot="{ field, errors, errorMessage }">
                            <input type="email" placeholder="Correo" v-model="landing_email" v-bind="field" class="form-control form-control-contact" :class="[{'is-invalid': errors.length>0}]">
                            <span class="text-danger">{{ errorMessage }}</span>
                         </Field>
                      </div>
                   </div>
                    <div class="col-md-12">
                        <Field name="terminos_condiciones" v-slot="{ field, errorMessage }" type="checkbox" :value="terminos_condiciones">
                             <div class="contact__form-agree d-flex align-items-center mb-20">
                                  <input class="e-check-input" type="checkbox" id="e-agree" v-model="terminos_condiciones" v-bind="field" >
                                  <label class="e-check-label" for="e-agree">He leído y acepto los <a href="https://cursos.logigacorp.com/terminos-y-condiciones" target="_blank">Términos y Condiciones</a></label>
                             </div>
                            <span class="text-danger">{{ errorMessage }}</span>
                        </Field>
                    </div>
                   <div class="col-xxl-12">
                      <div class="contact__btn">
                         <button type="submit" class="e-btn">Enviar</button>
                      </div>
                   </div>
                </div>
             </Form>
          </div> -->
          <section class="about__area">
            <div class="container">
                <div class="row">
                <div class="col-xxl-6 offset-xxl-1 col-xl-6 col-lg-6" style="margin-left:0;">
                    <div class="about__thumb-wrapper">
                        <div class="about__thumb text-center" style="font-size:34px;">
                            <span class="fecha_semillero page__title-pre green-bg hero__quote-animation" style="font-size: 34px!important;padding-top: 20px; background-color: #0F80C1;">Inicio: 17 de Marzo del 2025 </span>
                            <img class="imagen_semillero" src="@/assets/img/about/portada_semillero_v3.jpg" alt="">
                        </div>
                        <div class="about__banner mt--210">
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <div class="about__content pl-70">
                        <div class="section__title-wrapper mb-25">
                            <h2 class="section__title"><span class="yellow-bg-big">Semillero: <img src="@/assets/img/shape/yellow-bg-2.png" alt=""></span>Ejecutivo<br> de cuenta aduanero </h2>
                            <p style="text-align: justify;">
                            El Proyecto "Semillero" ha sido meticulosamente diseñado para garantizar una experiencia de aprendizaje efectiva y práctica.
                            Este plan de estudios sólido y relevante abarca todos los aspectos claves que un ejecutivo de comercio exterior debe tener. Brindamos:
                            </p>
                        </div>
                        <div class="about__list mb-35">
                            <ul>
                            <li class="d-flex align-items-center"> <span>&#10003;</span> Certificado de Asistencia por cada Módulo</li>
                            <li class="d-flex align-items-center"> <span>&#10003;</span> Examen de Habilidades Blandas al finalizar el programa</li>
                            <li class="d-flex align-items-center"> <span>&#10003;</span> Certificado de Cumplimiento Exitoso del Programa</li>
                            <li class="d-flex align-items-center"> <span>&#10003;</span> Informe Gerencial para bolsa de trabajo ADA</li>
                            </ul>
                        </div>
                        <a class="e-btn e-btn-border" href="https://cursos.logigacorp.com/programa/semillero" target="_blank">Ver programa</a>
                    </div>
                </div>
                </div>
            </div>
        </section>
      </b-modal>
    </div>
    <Header headerShadow="header__area header__padding header__padding-2 header__shadow"/>
    <HeroSection/>
    <ProgramaSemilleroArea/>
    <Services/>
<!--    <CategoryArea/>-->
    <Course/>
    <BannerArea/>
<!--    <Events/>-->
<!--    <Pricing/>-->
    <Footer footerPadding="true"/>
 </template>

 <script>
import Header from '../components/Home/Header.vue';
import HeroSection from '../components/Home/HeroSection.vue';
import Services from '../components/HomeTwo/Services.vue';
import ProgramaSemilleroArea from '../components/common/ProgramaSemilleroArea.vue';
// import CategoryArea from '../components/Home/CategoryArea.vue';
import BannerArea from '../components/Home/BannerArea.vue';
import Course from '../components/Home/Course.vue';
// import Events from '../components/Home/Events.vue';
// import Pricing from '../components/Home/Pricing.vue';
import Footer from '../components/Home/Footer.vue';
import axios from "@/axios";
// import { Field, Form } from 'vee-validate';

 export default {
     name:'HomePage',
     components:{
         Header,
         HeroSection,
         Services,
         ProgramaSemilleroArea,
         // CategoryArea,
         BannerArea,
         Course,
         // Events,
         // Pricing,
         Footer,
        //   Form,
        //   Field
     },
     data () {
        return {
            mostrarModal: false,
            codigo: this.$route.params.codigo,
            landing_nombres: '',
            landing_email: '',
             checkoutSchema: {
                nombres: 'required',
                email: 'required',
               terminos_condiciones: 'terminosCondiciones',
             },
            terminos_condiciones: '',
        }
     },
     mounted() {
         if(window.innerWidth > 576){
             this.mostrarModal = true
         }
     },
     methods: {
          submitContactForm(){
              this.procesarContactForm()
          },
          procesarContactForm(){
            axios.post('api/curso/form_landing_page/',{
                curso: this.$route.params.token,
                nombres: this.landing_nombres,
                email: this.landing_email,
            }).then(
                response => {
                    if(response.data.status == 'success'){
                        this.$swal({
                            title: 'Formulario enviado',
                            text: response.data.message,
                            icon: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#0F80C1',
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cancelar',
                        }).then(result => {
                            console.log(result)
                            this.mostrarModal = false
                            this.$router.push("/")
                        })
                    }
                    else{
                        this.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            confirmButtonColor: '#0F80C1',
                            confirmButtonText: 'Aceptar',
                        })
                    }
                }
            )
          }
     },
     computed: {
          getValidationSchema(){
              return this.checkoutSchema
          }
     }
 }
 </script>

 <style text="css">
    #modal-landing .modal-content{
        background-color: #fff;
    }
    .modal-content button.btn-close{
        background: transparent!important;
    }

    .modal-dialog{
        max-width: 1050px!important;
    }

    @media (min-width: 576px){
        .imagen_semillero{
            width:530px!important;
        }
        .fecha_semillero{
            width:530px!important;
            height:64px!important;
        }
    }

    @media (max-width: 576px){
        .modal{
            display: none;
        }
    }
 </style>
